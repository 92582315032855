.button {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 12px 24px;
  border: 1px solid #a12727;
  border-radius: 8px;
  background: #69e244;
  cursor: pointer;
  border: none;
  font: normal normal bold 20px arial;
  color: #ffffff;
  text-decoration: none;
}
.breathingButton {
  width: 180px;
  padding: 12px;
  margin: 50px auto;
  border: 1px solid #3cc8b4;
  -webkit-animation: breathing 2s ease-out infinite normal;
  animation: breathing 2s ease-out infinite normal;
  font-family: "Lantinghei SC";
  font-size: 16px;
  background-color: #1eac3f;
  color: white;
  -webkit-font-smoothing: antialiased;
  border-radius: 2px;
  text-align: center;
}

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
